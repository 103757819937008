.content-header{
    display: flex;
    flex-direction: column;
    background-color: #ededed;
    height: 500px;
    width: 20%;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;  
}

.content-header div{
    margin-top: 10px;
    overflow: auto;
}

.content-header div::-webkit-scrollbar{
    width: 0.5em;
}

.content-header div::-webkit-scrollbar-track{
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.content-header div::-webkit-scrollbar-thumb{
    background-color: rgb(115, 135, 156, 1);
    outline: 1px solid rgb(179, 193, 207);
}

.content-body{
    background-color: #73879c;
    height: 500px;
    width: 100%;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
}

.number-cell-format{
   text-align: right;
}