@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap);
.ringing-bell {
    position: absolute;
    left: 50%;
    top: 50%;
    transition: translate(-50%, -50%)
}

.faa-ring {
    color: gray;
    cursor: pointer;
}

@keyframes ring {
    0% {
        transform: rotate(-15deg);
        color:#c41c1c
    }

    2% {
        transform: rotate(15deg)
    }

    4% {
        transform: rotate(-18deg)
    }

    6% {
        transform: rotate(18deg)
    }

    8% {
        transform: rotate(-22deg)
    }

    10% {
        transform: rotate(22deg)
    }

    12% {
        transform: rotate(-18deg)
    }

    14% {
        transform: rotate(18deg)
    }

    16% {
        transform: rotate(-12deg)
    }

    18% {
        transform: rotate(12deg)
    }

    20% {
        transform: rotate(0deg)
    }
}

.faa-ring.animated,
.faa-ring.animated-hover:hover,
.faa-parent.animated-hover:hover>.faa-ring {
    animation: ring 1.5s ease infinite;
    transform-origin-x: 50%;
    transform-origin-y: 0px;
    transform-origin-z: initial;
}
@page { margin: 0 }
body { margin: 0 }
.sheet {
  margin: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: always;
}

/** Paper sizes **/
body.A3           .sheet { width: 297mm; height: 419mm }
body.A3.landscape .sheet { width: 420mm; height: 296mm }
body.A4           .sheet { width: 210mm; height: 296mm }
body.A4.landscape .sheet { width: 297mm; height: 209mm }
body.A5           .sheet { width: 148mm; height: 209mm }
body.A5.landscape .sheet { width: 210mm; height: 147mm }

/** Padding area **/
.sheet.padding-10mm { padding: 10mm }
.sheet.padding-15mm { padding: 15mm }
.sheet.padding-20mm { padding: 20mm }
.sheet.padding-25mm { padding: 25mm }

/** For screen preview **/
@media screen {
  body { background: #e0e0e0 }
  .sheet {
    background: white;
    box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
    margin: 5mm;
  }
}

/** Fix for Chrome issue #273306 **/
@media print {
           body.A3.landscape { width: 420mm }
  body.A3, body.A4.landscape { width: 297mm }
  body.A4, body.A5.landscape { width: 210mm }
  body.A5                    { width: 148mm }
}
.table-pdf{
    width: 100%;
    text-align: center;
    padding: 0;
    margin: auto;
}

.table-label{
    width: 10%;
    font-size: 12px !important;
    text-align: left;
}

.table-data{
    width: 40%;
    font-size: 12px !important;
    text-align: left;
}

.table-company-label{
    width: 25%;
    font-size: 12px  !important;
    text-align: left;
}

.item-data{
    border: 1px solid #dddddd;
    font-size: 14px !important;
}

.width-8{
    width: 8%;
}

.width-10{
    width: 10%;
}

.width-15{
    width: 15%;
}

.width-20{
    width: 20%;
}

.width-45{
    width: 45%;
}

.width-67{
    width: 67%;
}

.font-13{
    font-size: 13px !important;
}

.font-16{
    font-size: 16px !important;
}

.text-align-right{
    text-align: right;
}

.text-align-left{
    text-align: left;
}
  .checkbox-wrapper-64 input[type="checkbox"] {
    visibility: hidden;
    display: none;
  }

  .checkbox-wrapper-64 *,
  .checkbox-wrapper-64 ::after,
  .checkbox-wrapper-64 ::before {
    box-sizing: border-box;
  }

  /* The switch - the box around the slider */
  .checkbox-wrapper-64 .switch {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 3.5em;
    height: 2em;
  }

  /* Hide default HTML checkbox */
  .checkbox-wrapper-64 .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .checkbox-wrapper-64 .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border: 1px solid #adb5bd;
    transition: .4s;
    border-radius: 30px;
  }

  .checkbox-wrapper-64 .slider:before {
    position: absolute;
    content: "";
    height: 1.4em;
    width: 1.4em;
    border-radius: 20px;
    left: 0.27em;
    bottom: 0.25em;
    background-color: #adb5bd;
    transition: .4s;
  }

  .checkbox-wrapper-64 input:checked + .slider {
    background-color: #007bff;
    border: 1px solid #007bff;
  }

  .checkbox-wrapper-64 input:focus + .slider {
    box-shadow: 0 0 1px #007bff;
  }

  .checkbox-wrapper-64 input:checked + .slider:before {
    transform: translateX(1.4em);
    background-color: #fff;
  }

  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    .checkbox-wrapper-14 input[type=checkbox] {
      --active: #007bff;
      --active-inner: #fff;
      --focus: 2px rgba(39, 94, 254, .3);
      --border: #BBC1E1;
      --border-hover: #007bff;
      --background: #fff;
      --disabled: #F6F8FF;
      --disabled-inner: #E1E6F9;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 21px;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: auto;
      display: block;;
      cursor: pointer;
      border: 1px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    }
    .checkbox-wrapper-14 input[type=checkbox]:after {
      content: "";
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
    }
    .checkbox-wrapper-14 input[type=checkbox]:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    .checkbox-wrapper-14 input[type=checkbox]:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: 0.9;
    }
    .checkbox-wrapper-14 input[type=checkbox]:disabled:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }
    .checkbox-wrapper-14 input[type=checkbox]:disabled + label {
      cursor: not-allowed;
    }
    .checkbox-wrapper-14 input[type=checkbox]:hover:not(:checked):not(:disabled) {
      --bc: var(--border-hover);
    }
    .checkbox-wrapper-14 input[type=checkbox]:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    .checkbox-wrapper-14 input[type=checkbox]:not(.switch) {
      width: 21px;
    }
    .checkbox-wrapper-14 input[type=checkbox]:not(.switch):after {
      opacity: var(--o, 0);
    }
    .checkbox-wrapper-14 input[type=checkbox]:not(.switch):checked {
      --o: 1;
    }
    .checkbox-wrapper-14 input[type=checkbox] + label {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      margin-left: 4px;
    }

    .checkbox-wrapper-14 input[type=checkbox]:not(.switch) {
      border-radius: 7px;
    }
    .checkbox-wrapper-14 input[type=checkbox]:not(.switch):after {
      width: 5px;
      height: 9px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 7px;
      top: 4px;
      transform: rotate(var(--r, 20deg));
    }
    .checkbox-wrapper-14 input[type=checkbox]:not(.switch):checked {
      --r: 43deg;
    }
    .checkbox-wrapper-14 input[type=checkbox].switch {
      width: 38px;
      border-radius: 11px;
    }
    .checkbox-wrapper-14 input[type=checkbox].switch:after {
      left: 2px;
      top: 2px;
      border-radius: 50%;
      width: 17px;
      height: 17px;
      background: var(--ab, var(--border));
      transform: translateX(var(--x, 0));
    }
    .checkbox-wrapper-14 input[type=checkbox].switch:checked {
      --ab: var(--active-inner);
      --x: 17px;
    }
    .checkbox-wrapper-14 input[type=checkbox].switch:disabled:not(:checked):after {
      opacity: 0.6;
    }
  }

  .checkbox-wrapper-14 * {
    box-sizing: inherit;
  }
  .checkbox-wrapper-14 *:before,
  .checkbox-wrapper-14 *:after {
    box-sizing: inherit;
  }
.orderItems_card {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

main.orderItems_table {
  width: 82vw;
  background-color: #fff5;
  margin-top: 30px;
  padding-bottom: 40px;
  margin-bottom: 20px;
  -webkit-backdrop-filter: blur(7px);
          backdrop-filter: blur(7px);
  box-shadow: 0 .4rem .8rem #0005;
  border-radius: .8rem;
  overflow: hidden;
}

.orderItems_table__header {
  width: 100%;
  height: 10%;
  background-color: #fff4;
  padding: .8rem 1rem;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table_header-h1 {
  font-size: 25px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: 700;
}

.table__orderItems__body {
  width: 95%;
  max-height: calc(109% - 1.6rem);
  background-color: #fffb;

  margin: .6rem auto;
  border-radius: .6rem;

  overflow: auto;
  overflow: overlay;
}

.table__orderItems__body_card {
  width: 95%;
  max-height: calc(109% - 1.6rem);
  background-color: #fffb;
  margin: .6rem auto;
  border-radius: .6rem;
  margin-top: 10px;

  overflow: auto;
  overflow: overlay;
  box-shadow: 5px 5px 10px #383737;
}

.table__orderItems__body::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.table__orderItems__body::-webkit-scrollbar-thumb {
  border-radius: .5rem;
  background-color: #0004;
  visibility: hidden;
}

.table__orderItems__body:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.table__orderItems {
  width: 100%;
}

.table__orderItems__td img {
  width: 36px;
  height: 36px;
  margin-right: .5rem;
  border-radius: 50%;

  vertical-align: middle;
}

.table__orderItems,
th,
.table__orderItems__td {
  border-collapse: collapse;
  padding: 1rem;
  font-weight: 900;
  border: none;
  font-weight: 700;
  color: #000;
}

.table__orderItems__thead .table__orderItems__th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #000000fe;
  cursor: pointer;
  text-transform: capitalize;
}

.table__orderItems__tbody .table__orderItems__tr:nth-child(even) {
  background-color: #0000000b;
}

.table__orderItems__tbody .table__orderItems__tr {
  --delay: .1s;
  transition: .5s ease-in-out var(--delay), background-color 0s;
}

.table__orderItems__tbody .table__orderItems__tr.hide {
  opacity: 0;
  transform: translateX(50%);
}

.table__orderItems__tbody .table__orderItems__tr:hover {
  background-color: #fff6 !important;
}

.table__orderItems__tbody .table__orderItems__tr .table__orderItems__td,
.table__orderItems__tbody .table__orderItems__tr .table__orderItems__td p,
.table__orderItems__tbody .table__orderItems__tr .table__orderItems__td img {
  transition: .2s ease-in-out;
}

.table__orderItems__tbody .table__orderItems__tr.hide .table__orderItems__td,
.table__orderItems__tbody .table__orderItems__tr.hide .table__orderItems__td p {
  padding: 0;
  font: 0 / 0 sans-serif;
  transition: .2s ease-in-out .5s;
}

.table__orderItems__tbody .table__orderItems__tr.hide .table__orderItems__td img {
  width: 0;
  height: 0;
  transition: .2s ease-in-out .5s;
}

.orderStatus {
  padding: .10rem 0;
  text-align: center;
  font-size: small;
  font-weight: bold;
  color: #fff;
  margin-top: 10px;
  margin-right: 1px;
  border-radius: 20%;
  border-color: #000000;
  border: 1.4px solid transparent;
  box-shadow: 5px 5px 10px #383737;
}

.table__orderItems__td:nth-of-type(3) {
  text-align: right;
}

.table__orderItems__td:nth-of-type(4) {
  text-align: right;
}

.table__orderItems__td:nth-of-type(5) {
  text-align: right;
}

.table__orderItems__td:nth-of-type(6) {
  text-align: right;
}

@media (max-width: 1000px) {
  .table__orderItems__td:not(:first-of-type) {
    min-width: 12.1rem;
  }
}

.table__orderItems__thead .table__orderItems__th span.icon-arrow {
  display: inline-block;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  border: 1.4px solid transparent;
  text-align: center;
  font-size: 1rem;
  margin-left: .5rem;
  transition: .2s ease-in-out;
}

.table__orderItems__thead .table__orderItems__th:hover span.icon-arrow {
  border: 1.4px solid #f1537df8;
}

.table__orderItems__thead .table__orderItems__th:hover {
  color: #f1537df8;
}

.table__orderItems__thead .table__orderItems__th.active span.icon-arrow {
  background-color: #f1537df8;
  color: #fff;
}

.table__orderItems__thead .table__orderItems__th.asc span.icon-arrow {
  transform: rotate(180deg);
}

.table__orderItems__thead .table__orderItems__th.active,
.table__orderItems__tbody .table__orderItems__td.active {
  color: #6c00bd;
}

.footer1 {
  background: #fff;
  display: block;
  color: #000;
}

.gradient_color_header {
	background: linear-gradient(-45deg, #007bff, #ffffff, #007bff, #ffffff);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


@media only screen and (min-width:991px) {
  .table__orderItems__body1 {
    background: transparent;
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .table__orderItems__td1 {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
}

@media only screen and (max-width:991px),
(min-device-width:768px) and (max-device-width:991px) {
  .table-container {
    width: 95%;
    background: transparent;
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .table__orderItems__tr {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
}

.single-orderitems-line {
  border-top: 4px solid #000;
  /* Adjust color and thickness as needed */
}

.single-orderitems-line {
  box-shadow: 1 1px 0 #000;
  /* Adjust color, horizontal offset, blur, and spread as needed */
}
#error{
    animation: fill1 0.5s ease forwards;
}

#error path:nth-child(1){
    stroke-dasharray: 1060px;
    stroke-dashoffset: 1060px;
    animation: line-anim 2s ease forwards;
}

@keyframes line-anim{
    to{
        stroke-dashoffset: 0;
    }
}

@keyframes fill1{
    /* from{
        fill:transparent;
    } */
    to{
        fill: '#73879C';
    }
}
@media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
  }
  
  @page {
    size: A4;
    margin: 10mm;
  }
.content-header{
    display: flex;
    flex-direction: column;
    background-color: #ededed;
    height: 500px;
    width: 20%;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;  
}

.content-header div{
    margin-top: 10px;
    overflow: auto;
}

.content-header div::-webkit-scrollbar{
    width: 0.5em;
}

.content-header div::-webkit-scrollbar-track{
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.content-header div::-webkit-scrollbar-thumb{
    background-color: rgb(115, 135, 156, 1);
    outline: 1px solid rgb(179, 193, 207);
}

.content-body{
    background-color: #73879c;
    height: 500px;
    width: 100%;
    padding: 5px;
    margin: 5px;
    border-radius: 5px;
}

.number-cell-format{
   text-align: right;
}
.fa-thumbs-up {
    background: -webkit-linear-gradient(180deg, #2a3f54, #4cdbf9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 4ps 3px rgba(102, 202, 205, 0.2);
    /* color:  #1c6977 */
}

.fa-thumbs-down {
    background: -webkit-linear-gradient(45deg, #fe6b8b 30%, #ff8f54 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0px 4px 5px 2px rgba(255, 105, 135, .3);
}

.fixed-delivery{
    background: -webkit-linear-gradient(139deg, #2c333a, #32f784);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 4ps 3px rgba(102, 202, 205, 0.2);
    font-weight: bold;
}

.link-button{
    text-decoration: underline !important; 
    -webkit-text-decoration-color: blue !important; 
            text-decoration-color: blue !important;
    color: blue !important;
    cursor: pointer;
}
/* @media (max-width: 480px) {
    .item-section1 {
        background-color: 'red'; 
        height: 100vh; 
        width: 70% 
    }

    .item-section1 {
        background-color: 'green'; 
        height: 100vh; 
        width: 30% 
    }
} */

.visually-hidden {
    position: absolute;
    top: auto;
    left: -10000px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.items-align{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.row-center{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.center{
    display: flex;
    align-items: center;
}

.background {
    display: flex;
    justify-content: space-evenly;
   /* align-items: center; */
    height: 100vh;
    width: 100%;
    font-family: 'Raleway',sans-serif;
    /* background: -webkit-linear-gradient(87deg, #2a3f54, #4cdbf9); */
    /* background: -webkit-linear-gradient(271deg, #ef426f 40%, #4cdbf9); */
    flex-direction: column;
    /* background: #777777; */
    background: #fff;
}

.header-logo{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 88px;
    position: absolute;
}

.logo-img{
    width: 20vh;
    height: 100%;
    object-fit: cover;
}

.font-style {
    font-family: 'Raleway',sans-serif;
}

.paper{
    position: relative;
    width: 50vw;
    min-height: 300px;
    background: rgba(0, 0,0, 0);
    border-radius: 10px;
    /* display: flex; */
    /* justify-content: center;
    align-items: center; */
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
    box-shadow: 0 25px 45px rgba(0,0,0,0.1);
    border: 1px solid #000 rgba(255,255,255,0.5);
    border-right: 1px solid rgba(255,255,255,0.2);
    border-bottom: 1px solid rgba(255,255,255,0.2);
    z-index: 2;
    margin: 30px 66px 30px;
    padding: 15px;
    /* overflow-y: scroll;
    height: calc(100%-20px); */
}

.paper-box{
    width: 39vw;
}

.number-input{
    background-color:  rgba(255,255,255,0.5) !important;
    border-radius: 20px  !important;
}

.flag-input{
    /* padding-left: 10px !important; */
    background-color:  rgba(255,255,255,0.5) !important;
    border-radius: 20px  !important;
}

.btn-category{
    color: #fff;
    background-color: #333233b5;
    border-color: #333233b5;
    width: 99%;
}

.active-item, .btn-category:hover{
    color: #fff;
    background-color: #333233;
    border-color: #333233;
}

.btn-category-fast{
    color: #fff;
    background-color: #f1537df8;
    border-color: #333233b5;
    width: 99%;
    height: 55px;
    text-transform: uppercase;
    font-weight: 900;
}

.btn-category-fast:hover{
    color: #fff;
    background-color: #f34674f8;
    border-color: #830048;
}

.item-card{
    height: auto;
    width: 98%;
    background-color: #fff;
    color: #000;
    display: flex;
    background: linear-gradient(
      to left top,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.5)
    );
    border-radius: 5px;
    margin: 0.5rem 0rem;
    padding: 0.5rem;
    box-shadow: 6px 6px 20px rgba(122, 122, 122, 0.212);
    justify-content: space-between;
    font-size: 14px;
    padding-left: 15px;
    /* font-family: 'Raleway', sans-serif; */
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.25px;
    margin:4px 0px 4px;
}

.row {
    display: flex;
    width: 100%;
  }

.margin{
    margin: 0px;
}  
  
.column {
    flex: 50% 1;
}

.remove-right-padding{
    padding-right: 0px;
}

tr > th{
    /* background-color: #333233b5; */
    background-color: #5f5e5f;
    color:#fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}

tfoot > tr > td{
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 1;
    background-color: #fff;
    border: 1px solid;
}

.button {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.number{
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    /* background-color: red; */
}

.center-items{
    align-items: center;
    justify-content:center !important;
}

.overflow-scroll{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    height: 83vh;
    width: 100%;
}

::-webkit-scrollbar {
    width: 4px;
    height: 12px;
  }
  
  /* Track */
/* .overflow-scroll ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  } */
   
  /* Handle */
::-webkit-scrollbar-thumb {
    background: #333233b5; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
/* .overflow-scroll ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  } */

.searchResult::-webkit-scrollbar{
    display: none;
}

.background-toast{
    background-color:#ee426f;
    /* left: 5em; */
    width: 50vw;
}  

.purchase{
    display: flex;
    height: 10vh;
    width: 100%;
    padding: 10px;
    flex-direction: column;
    align-items: center;
}  

.btn-purchase{
    width: 50%;
    height: 100%;
    text-transform: uppercase;
    font-size: 18px;
    color: #fff;
    background-color: #f74b78;
    box-shadow: 0px;
    letter-spacing: 1px;
    border-radius: 50px;
    border: 0px;
    /* transition: ease-in, width .35s ease-out; */
}

.btn-purchase:disabled{
    background-color: #333233b5;
}

.btn:focus{
    outline: 0px auto !important;
}

.btn-purchase:focus{
    outline: 0px auto !important;
}

.header{
    color: #333233b5;
}

.underline{
    text-decoration: underline;
    color: #333233b5;
}

.padding{
    padding-top: 1px;
    padding-bottom: 4px;
}

.cartSection{
    visibility: hidden;
}

.cartSectionClose{
    visibility: hidden;
}

.btn-section{
    float: right;
    justify-content: flex-end;
    inline-size: auto;
}

.bar{
    display: flex !important;
    flex-direction: row-reverse;
    padding: 10px 10px 0px;
}

.badge{
    position: absolute;
    top: 4px;
    right: 1px;
    padding: 5px 10px;
    border-radius: 50%;
    background: red;
    color: white;
}

.uppercase{
    text-transform: uppercase;
}

.capatalize{
    text-transform: capitalize;
}

.message{
    font-size: 16px;
    color:#333233b5;
}

.message-footer{
    text-align: center;
    bottom: 64px;
    position: absolute;
    width: 100%
}

.spacing-1{
    letter-spacing: 1px;
}

.btn-confirm{
    width: 41%; 
    height: 6vh; 
}

.text-align{
    text-align: center;
}

.icon{
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    cursor: pointer;
}

.search.active-search{
    width: 360px;
    box-shadow: 0 0 0 1px #333233b5;
}

.search{
    position: relative;
    width: 40px;
    height: 40px;
    background: #fff; 
    border-radius: 60px; 
    transition: 0.5s; 
    box-shadow: 0 0 0 1px #333233b5;
    overflow: hidden;
}

.input{
    position: relative;
    width: 300px;
    height: 40px;
    left: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input input{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
}

.dropdown-1{
    height: 0px;
    /* z-index: 5; */
    display: none;
    background: rgb(175, 168, 168);
    width: 360px;
    margin: 10px;
    color: #fff;
}

.active-dropdown-1{
    height: 40vh;
    z-index: 5;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.searchItem{
    z-index: 6;
    padding: 7px;
    border-bottom: 0.1px solid black;
    cursor: pointer;
}

.display-none{
    display: none;
}

.active-button{
    z-index: 5;
    /* width: 20px; */
    display: flex;
    font-size: 18px;
    align-items: center;
    cursor: pointer;
}

/* media  */
@media (max-height:504px) {
    /* .overflow-scroll{
        height:70vh ;
    } */
}

@media (max-width:1254px) {
    .paper-box{
        width: 50vw;
    }
}

@media (max-width:1050px) {
    .paper{
        margin: 30px 30px 30px;
    }

    .column{
        flex: 25% 1;
    }

    .item-card{
        height: auto;
    }

    .item-card .row{
        width: 100%;
    }

    .btn-section{
        inline-size: -webkit-max-content !important;
        inline-size: max-content !important;
    }
}

@media (max-width:950px) {
    .btn-purchase{
        width: 75%;
    }

    .paper-box{
        width: 70vw;
    }
}

@media (max-width:850px) {
    .column{
        flex: 100% 1;
    }

    .btn-purchase{
        font-size: 15px;
    }
}

@media (max-width: 767px) {
    .background{
        justify-content: unset !important;
        flex-direction: column;
    }
    .background-toast{
        width: 100vw;
    } 

    .center-items{
        align-items: center;
        justify-content:center !important;
        padding: 10px;
    }

    .column{
        flex: 50% 1;
    }

    .row{
        width: unset;
    }

    .header-logo{
        height: 50px;
    }

    .item-card .row{
        width: 100%;
    }

    .paper{
        width: 100%;
        margin: 10px 20px 10px;
    }

    .cartSection{
        z-index: 10;
        visibility: visible;

    }

    .cartSectionClose{
        visibility: hidden;
    }

    .item-card{
        height: auto;
    }

    .header{
        font-size: 25px;
    }
}

@media (max-width: 613px) {
    .column{
        flex: 25% 1;
    }
}

@media (max-width: 510px) {
    .row-padding{
        padding-top: 55px;
    }

    .paper{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 30px 10px;
    }

    .cartSection{
        z-index: 10;
        visibility: visible;
    }

    .cartSectionClose{
        visibility: hidden;
    }

    .paper-box{
        width: 98%;
        align-items:unset;
    }

    .message-footer{
        bottom: 30px;
    }

    .search.active-search{
        width: 250px;
    }

    .input{
        width: 190px;
    }

    .dropdown-1{
        width: 250px;
    }

}

@media (max-width: 473px) {
    .btn-section{
        inline-size: -webkit-max-content;
        inline-size: max-content;
    }

    .column{
        flex: 100% 1;
    }

    .item-card{
        height: auto;
    }

    .message{
        font-size: 14px;
    }
}

@media (max-width: 375px) {

    .column{
        flex: 100% 1;
    }

    .paper{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 15px 10px;
    }

    .item-card{
        height: auto;
    }

    .cartSection{
        z-index: 10;
        visibility: visible;
    }

    .number-input{
        width: 197px !important;  
    }

    .header{
        font-size: 20px;
    }

    .btn-purchase{
        width: 90%;
        /* font-size: 14px; */
    }

    .message{
        font-size: 14px;
    }

    .paper-box{
        width: 98%;
        align-items:unset;
    }

    .message-footer{
        bottom: 30px;
        width: 100vw;
    }

    .btn-confirm{
        width: 80%; 
        height: 6vh;
    }
}
