.table-pdf{
    width: 100%;
    text-align: center;
    padding: 0;
    margin: auto;
}

.table-label{
    width: 10%;
    font-size: 12px !important;
    text-align: left;
}

.table-data{
    width: 40%;
    font-size: 12px !important;
    text-align: left;
}

.table-company-label{
    width: 25%;
    font-size: 12px  !important;
    text-align: left;
}

.item-data{
    border: 1px solid #dddddd;
    font-size: 14px !important;
}

.width-8{
    width: 8%;
}

.width-10{
    width: 10%;
}

.width-15{
    width: 15%;
}

.width-20{
    width: 20%;
}

.width-45{
    width: 45%;
}

.width-67{
    width: 67%;
}

.font-13{
    font-size: 13px !important;
}

.font-16{
    font-size: 16px !important;
}

.text-align-right{
    text-align: right;
}

.text-align-left{
    text-align: left;
}