.fa-thumbs-up {
    background: -webkit-linear-gradient(180deg, #2a3f54, #4cdbf9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 4ps 3px rgba(102, 202, 205, 0.2);
    /* color:  #1c6977 */
}

.fa-thumbs-down {
    background: -webkit-linear-gradient(45deg, #fe6b8b 30%, #ff8f54 80%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0px 4px 5px 2px rgba(255, 105, 135, .3);
}

.fixed-delivery{
    background: -webkit-linear-gradient(139deg, #2c333a, #32f784);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 2px 4ps 3px rgba(102, 202, 205, 0.2);
    font-weight: bold;
}

.link-button{
    text-decoration: underline !important; 
    text-decoration-color: blue !important;
    color: blue !important;
    cursor: pointer;
}