  .checkbox-wrapper-64 input[type="checkbox"] {
    visibility: hidden;
    display: none;
  }

  .checkbox-wrapper-64 *,
  .checkbox-wrapper-64 ::after,
  .checkbox-wrapper-64 ::before {
    box-sizing: border-box;
  }

  /* The switch - the box around the slider */
  .checkbox-wrapper-64 .switch {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 3.5em;
    height: 2em;
  }

  /* Hide default HTML checkbox */
  .checkbox-wrapper-64 .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .checkbox-wrapper-64 .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    border: 1px solid #adb5bd;
    transition: .4s;
    border-radius: 30px;
  }

  .checkbox-wrapper-64 .slider:before {
    position: absolute;
    content: "";
    height: 1.4em;
    width: 1.4em;
    border-radius: 20px;
    left: 0.27em;
    bottom: 0.25em;
    background-color: #adb5bd;
    transition: .4s;
  }

  .checkbox-wrapper-64 input:checked + .slider {
    background-color: #007bff;
    border: 1px solid #007bff;
  }

  .checkbox-wrapper-64 input:focus + .slider {
    box-shadow: 0 0 1px #007bff;
  }

  .checkbox-wrapper-64 input:checked + .slider:before {
    transform: translateX(1.4em);
    background-color: #fff;
  }

  @supports (-webkit-appearance: none) or (-moz-appearance: none) {
    .checkbox-wrapper-14 input[type=checkbox] {
      --active: #007bff;
      --active-inner: #fff;
      --focus: 2px rgba(39, 94, 254, .3);
      --border: #BBC1E1;
      --border-hover: #007bff;
      --background: #fff;
      --disabled: #F6F8FF;
      --disabled-inner: #E1E6F9;
      -webkit-appearance: none;
      -moz-appearance: none;
      height: 21px;
      outline: none;
      display: inline-block;
      vertical-align: top;
      position: relative;
      margin: auto;
      display: block;;
      cursor: pointer;
      border: 1px solid var(--bc, var(--border));
      background: var(--b, var(--background));
      transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    }
    .checkbox-wrapper-14 input[type=checkbox]:after {
      content: "";
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
    }
    .checkbox-wrapper-14 input[type=checkbox]:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: .3s;
      --d-t: .6s;
      --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }
    .checkbox-wrapper-14 input[type=checkbox]:disabled {
      --b: var(--disabled);
      cursor: not-allowed;
      opacity: 0.9;
    }
    .checkbox-wrapper-14 input[type=checkbox]:disabled:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }
    .checkbox-wrapper-14 input[type=checkbox]:disabled + label {
      cursor: not-allowed;
    }
    .checkbox-wrapper-14 input[type=checkbox]:hover:not(:checked):not(:disabled) {
      --bc: var(--border-hover);
    }
    .checkbox-wrapper-14 input[type=checkbox]:focus {
      box-shadow: 0 0 0 var(--focus);
    }
    .checkbox-wrapper-14 input[type=checkbox]:not(.switch) {
      width: 21px;
    }
    .checkbox-wrapper-14 input[type=checkbox]:not(.switch):after {
      opacity: var(--o, 0);
    }
    .checkbox-wrapper-14 input[type=checkbox]:not(.switch):checked {
      --o: 1;
    }
    .checkbox-wrapper-14 input[type=checkbox] + label {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer;
      margin-left: 4px;
    }

    .checkbox-wrapper-14 input[type=checkbox]:not(.switch) {
      border-radius: 7px;
    }
    .checkbox-wrapper-14 input[type=checkbox]:not(.switch):after {
      width: 5px;
      height: 9px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 7px;
      top: 4px;
      transform: rotate(var(--r, 20deg));
    }
    .checkbox-wrapper-14 input[type=checkbox]:not(.switch):checked {
      --r: 43deg;
    }
    .checkbox-wrapper-14 input[type=checkbox].switch {
      width: 38px;
      border-radius: 11px;
    }
    .checkbox-wrapper-14 input[type=checkbox].switch:after {
      left: 2px;
      top: 2px;
      border-radius: 50%;
      width: 17px;
      height: 17px;
      background: var(--ab, var(--border));
      transform: translateX(var(--x, 0));
    }
    .checkbox-wrapper-14 input[type=checkbox].switch:checked {
      --ab: var(--active-inner);
      --x: 17px;
    }
    .checkbox-wrapper-14 input[type=checkbox].switch:disabled:not(:checked):after {
      opacity: 0.6;
    }
  }

  .checkbox-wrapper-14 * {
    box-sizing: inherit;
  }
  .checkbox-wrapper-14 *:before,
  .checkbox-wrapper-14 *:after {
    box-sizing: inherit;
  }