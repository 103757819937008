.orderItems_card {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

main.orderItems_table {
  width: 82vw;
  background-color: #fff5;
  margin-top: 30px;
  padding-bottom: 40px;
  margin-bottom: 20px;
  backdrop-filter: blur(7px);
  box-shadow: 0 .4rem .8rem #0005;
  border-radius: .8rem;
  overflow: hidden;
}

.orderItems_table__header {
  width: 100%;
  height: 10%;
  background-color: #fff4;
  padding: .8rem 1rem;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table_header-h1 {
  font-size: 25px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-weight: 700;
}

.table__orderItems__body {
  width: 95%;
  max-height: calc(109% - 1.6rem);
  background-color: #fffb;

  margin: .6rem auto;
  border-radius: .6rem;

  overflow: auto;
  overflow: overlay;
}

.table__orderItems__body_card {
  width: 95%;
  max-height: calc(109% - 1.6rem);
  background-color: #fffb;
  margin: .6rem auto;
  border-radius: .6rem;
  margin-top: 10px;

  overflow: auto;
  overflow: overlay;
  box-shadow: 5px 5px 10px #383737;
}

.table__orderItems__body::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.table__orderItems__body::-webkit-scrollbar-thumb {
  border-radius: .5rem;
  background-color: #0004;
  visibility: hidden;
}

.table__orderItems__body:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.table__orderItems {
  width: 100%;
}

.table__orderItems__td img {
  width: 36px;
  height: 36px;
  margin-right: .5rem;
  border-radius: 50%;

  vertical-align: middle;
}

.table__orderItems,
th,
.table__orderItems__td {
  border-collapse: collapse;
  padding: 1rem;
  font-weight: 900;
  border: none;
  font-weight: 700;
  color: #000;
}

.table__orderItems__thead .table__orderItems__th {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #000000fe;
  cursor: pointer;
  text-transform: capitalize;
}

.table__orderItems__tbody .table__orderItems__tr:nth-child(even) {
  background-color: #0000000b;
}

.table__orderItems__tbody .table__orderItems__tr {
  --delay: .1s;
  transition: .5s ease-in-out var(--delay), background-color 0s;
}

.table__orderItems__tbody .table__orderItems__tr.hide {
  opacity: 0;
  transform: translateX(50%);
}

.table__orderItems__tbody .table__orderItems__tr:hover {
  background-color: #fff6 !important;
}

.table__orderItems__tbody .table__orderItems__tr .table__orderItems__td,
.table__orderItems__tbody .table__orderItems__tr .table__orderItems__td p,
.table__orderItems__tbody .table__orderItems__tr .table__orderItems__td img {
  transition: .2s ease-in-out;
}

.table__orderItems__tbody .table__orderItems__tr.hide .table__orderItems__td,
.table__orderItems__tbody .table__orderItems__tr.hide .table__orderItems__td p {
  padding: 0;
  font: 0 / 0 sans-serif;
  transition: .2s ease-in-out .5s;
}

.table__orderItems__tbody .table__orderItems__tr.hide .table__orderItems__td img {
  width: 0;
  height: 0;
  transition: .2s ease-in-out .5s;
}

.orderStatus {
  padding: .10rem 0;
  text-align: center;
  font-size: small;
  font-weight: bold;
  color: #fff;
  margin-top: 10px;
  margin-right: 1px;
  border-radius: 20%;
  border-color: #000000;
  border: 1.4px solid transparent;
  box-shadow: 5px 5px 10px #383737;
}

.table__orderItems__td:nth-of-type(3) {
  text-align: right;
}

.table__orderItems__td:nth-of-type(4) {
  text-align: right;
}

.table__orderItems__td:nth-of-type(5) {
  text-align: right;
}

.table__orderItems__td:nth-of-type(6) {
  text-align: right;
}

@media (max-width: 1000px) {
  .table__orderItems__td:not(:first-of-type) {
    min-width: 12.1rem;
  }
}

.table__orderItems__thead .table__orderItems__th span.icon-arrow {
  display: inline-block;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  border: 1.4px solid transparent;
  text-align: center;
  font-size: 1rem;
  margin-left: .5rem;
  transition: .2s ease-in-out;
}

.table__orderItems__thead .table__orderItems__th:hover span.icon-arrow {
  border: 1.4px solid #f1537df8;
}

.table__orderItems__thead .table__orderItems__th:hover {
  color: #f1537df8;
}

.table__orderItems__thead .table__orderItems__th.active span.icon-arrow {
  background-color: #f1537df8;
  color: #fff;
}

.table__orderItems__thead .table__orderItems__th.asc span.icon-arrow {
  transform: rotate(180deg);
}

.table__orderItems__thead .table__orderItems__th.active,
.table__orderItems__tbody .table__orderItems__td.active {
  color: #6c00bd;
}

.footer1 {
  background: #fff;
  display: block;
  color: #000;
}

.gradient_color_header {
	background: linear-gradient(-45deg, #007bff, #ffffff, #007bff, #ffffff);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


@media only screen and (min-width:991px) {
  .table__orderItems__body1 {
    background: transparent;
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .table__orderItems__td1 {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
}

@media only screen and (max-width:991px),
(min-device-width:768px) and (max-device-width:991px) {
  .table-container {
    width: 95%;
    background: transparent;
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .table__orderItems__tr {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
}

.single-orderitems-line {
  border-top: 4px solid #000;
  /* Adjust color and thickness as needed */
}

.single-orderitems-line {
  box-shadow: 1 1px 0 #000;
  /* Adjust color, horizontal offset, blur, and spread as needed */
}