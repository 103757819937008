/* @media (max-width: 480px) {
    .item-section1 {
        background-color: 'red'; 
        height: 100vh; 
        width: 70% 
    }

    .item-section1 {
        background-color: 'green'; 
        height: 100vh; 
        width: 30% 
    }
} */

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');

.visually-hidden {
    position: absolute;
    top: auto;
    left: -10000px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.items-align{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.row-center{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.center{
    display: flex;
    align-items: center;
}

.background {
    display: flex;
    justify-content: space-evenly;
   /* align-items: center; */
    height: 100vh;
    width: 100%;
    font-family: 'Raleway',sans-serif;
    /* background: -webkit-linear-gradient(87deg, #2a3f54, #4cdbf9); */
    /* background: -webkit-linear-gradient(271deg, #ef426f 40%, #4cdbf9); */
    flex-direction: column;
    /* background: #777777; */
    background: #fff;
}

.header-logo{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 88px;
    position: absolute;
}

.logo-img{
    width: 20vh;
    height: 100%;
    object-fit: cover;
}

.font-style {
    font-family: 'Raleway',sans-serif;
}

.paper{
    position: relative;
    width: 50vw;
    min-height: 300px;
    background: rgba(0, 0,0, 0);
    border-radius: 10px;
    /* display: flex; */
    /* justify-content: center;
    align-items: center; */
    backdrop-filter: blur(5px);
    box-shadow: 0 25px 45px rgba(0,0,0,0.1);
    border: 1px solid #000 rgba(255,255,255,0.5);
    border-right: 1px solid rgba(255,255,255,0.2);
    border-bottom: 1px solid rgba(255,255,255,0.2);
    z-index: 2;
    margin: 30px 66px 30px;
    padding: 15px;
    /* overflow-y: scroll;
    height: calc(100%-20px); */
}

.paper-box{
    width: 39vw;
}

.number-input{
    background-color:  rgba(255,255,255,0.5) !important;
    border-radius: 20px  !important;
}

.flag-input{
    /* padding-left: 10px !important; */
    background-color:  rgba(255,255,255,0.5) !important;
    border-radius: 20px  !important;
}

.btn-category{
    color: #fff;
    background-color: #333233b5;
    border-color: #333233b5;
    width: 99%;
}

.active-item, .btn-category:hover{
    color: #fff;
    background-color: #333233;
    border-color: #333233;
}

.btn-category-fast{
    color: #fff;
    background-color: #f1537df8;
    border-color: #333233b5;
    width: 99%;
    height: 55px;
    text-transform: uppercase;
    font-weight: 900;
}

.btn-category-fast:hover{
    color: #fff;
    background-color: #f34674f8;
    border-color: #830048;
}

.item-card{
    height: auto;
    width: 98%;
    background-color: #fff;
    color: #000;
    display: flex;
    background: linear-gradient(
      to left top,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.5)
    );
    border-radius: 5px;
    margin: 0.5rem 0rem;
    padding: 0.5rem;
    box-shadow: 6px 6px 20px rgba(122, 122, 122, 0.212);
    justify-content: space-between;
    font-size: 14px;
    padding-left: 15px;
    /* font-family: 'Raleway', sans-serif; */
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.25px;
    margin:4px 0px 4px;
}

.row {
    display: flex;
    width: 100%;
  }

.margin{
    margin: 0px;
}  
  
.column {
    flex: 50%;
}

.remove-right-padding{
    padding-right: 0px;
}

tr > th{
    /* background-color: #333233b5; */
    background-color: #5f5e5f;
    color:#fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: sticky;
    top: 0;
    z-index: 1;
}

tfoot > tr > td{
    position: sticky;
    bottom: 0;
    z-index: 1;
    background-color: #fff;
    border: 1px solid;
}

.button {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.number{
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    /* background-color: red; */
}

.center-items{
    align-items: center;
    justify-content:center !important;
}

.overflow-scroll{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    height: 83vh;
    width: 100%;
}

::-webkit-scrollbar {
    width: 4px;
    height: 12px;
  }
  
  /* Track */
/* .overflow-scroll ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  } */
   
  /* Handle */
::-webkit-scrollbar-thumb {
    background: #333233b5; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
/* .overflow-scroll ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  } */

.searchResult::-webkit-scrollbar{
    display: none;
}

.background-toast{
    background-color:#ee426f;
    /* left: 5em; */
    width: 50vw;
}  

.purchase{
    display: flex;
    height: 10vh;
    width: 100%;
    padding: 10px;
    flex-direction: column;
    align-items: center;
}  

.btn-purchase{
    width: 50%;
    height: 100%;
    text-transform: uppercase;
    font-size: 18px;
    color: #fff;
    background-color: #f74b78;
    box-shadow: 0px;
    letter-spacing: 1px;
    border-radius: 50px;
    border: 0px;
    /* transition: ease-in, width .35s ease-out; */
}

.btn-purchase:disabled{
    background-color: #333233b5;
}

.btn:focus{
    outline: 0px auto !important;
}

.btn-purchase:focus{
    outline: 0px auto !important;
}

.header{
    color: #333233b5;
}

.underline{
    text-decoration: underline;
    color: #333233b5;
}

.padding{
    padding-top: 1px;
    padding-bottom: 4px;
}

.cartSection{
    visibility: hidden;
}

.cartSectionClose{
    visibility: hidden;
}

.btn-section{
    float: right;
    justify-content: flex-end;
    inline-size: auto;
}

.bar{
    display: flex !important;
    flex-direction: row-reverse;
    padding: 10px 10px 0px;
}

.badge{
    position: absolute;
    top: 4px;
    right: 1px;
    padding: 5px 10px;
    border-radius: 50%;
    background: red;
    color: white;
}

.uppercase{
    text-transform: uppercase;
}

.capatalize{
    text-transform: capitalize;
}

.message{
    font-size: 16px;
    color:#333233b5;
}

.message-footer{
    text-align: center;
    bottom: 64px;
    position: absolute;
    width: 100%
}

.spacing-1{
    letter-spacing: 1px;
}

.btn-confirm{
    width: 41%; 
    height: 6vh; 
}

.text-align{
    text-align: center;
}

.icon{
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    cursor: pointer;
}

.search.active-search{
    width: 360px;
    box-shadow: 0 0 0 1px #333233b5;
}

.search{
    position: relative;
    width: 40px;
    height: 40px;
    background: #fff; 
    border-radius: 60px; 
    transition: 0.5s; 
    box-shadow: 0 0 0 1px #333233b5;
    overflow: hidden;
}

.input{
    position: relative;
    width: 300px;
    height: 40px;
    left: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input input{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
}

.dropdown-1{
    height: 0px;
    /* z-index: 5; */
    display: none;
    background: rgb(175, 168, 168);
    width: 360px;
    margin: 10px;
    color: #fff;
}

.active-dropdown-1{
    height: 40vh;
    z-index: 5;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.searchItem{
    z-index: 6;
    padding: 7px;
    border-bottom: 0.1px solid black;
    cursor: pointer;
}

.display-none{
    display: none;
}

.active-button{
    z-index: 5;
    /* width: 20px; */
    display: flex;
    font-size: 18px;
    align-items: center;
    cursor: pointer;
}

/* media  */
@media (max-height:504px) {
    /* .overflow-scroll{
        height:70vh ;
    } */
}

@media (max-width:1254px) {
    .paper-box{
        width: 50vw;
    }
}

@media (max-width:1050px) {
    .paper{
        margin: 30px 30px 30px;
    }

    .column{
        flex: 25%;
    }

    .item-card{
        height: auto;
    }

    .item-card .row{
        width: 100%;
    }

    .btn-section{
        inline-size: max-content !important;
    }
}

@media (max-width:950px) {
    .btn-purchase{
        width: 75%;
    }

    .paper-box{
        width: 70vw;
    }
}

@media (max-width:850px) {
    .column{
        flex: 100%;
    }

    .btn-purchase{
        font-size: 15px;
    }
}

@media (max-width: 767px) {
    .background{
        justify-content: unset !important;
        flex-direction: column;
    }
    .background-toast{
        width: 100vw;
    } 

    .center-items{
        align-items: center;
        justify-content:center !important;
        padding: 10px;
    }

    .column{
        flex: 50%;
    }

    .row{
        width: unset;
    }

    .header-logo{
        height: 50px;
    }

    .item-card .row{
        width: 100%;
    }

    .paper{
        width: 100%;
        margin: 10px 20px 10px;
    }

    .cartSection{
        z-index: 10;
        visibility: visible;

    }

    .cartSectionClose{
        visibility: hidden;
    }

    .item-card{
        height: auto;
    }

    .header{
        font-size: 25px;
    }
}

@media (max-width: 613px) {
    .column{
        flex: 25%;
    }
}

@media (max-width: 510px) {
    .row-padding{
        padding-top: 55px;
    }

    .paper{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 30px 10px;
    }

    .cartSection{
        z-index: 10;
        visibility: visible;
    }

    .cartSectionClose{
        visibility: hidden;
    }

    .paper-box{
        width: 98%;
        align-items:unset;
    }

    .message-footer{
        bottom: 30px;
    }

    .search.active-search{
        width: 250px;
    }

    .input{
        width: 190px;
    }

    .dropdown-1{
        width: 250px;
    }

}

@media (max-width: 473px) {
    .btn-section{
        inline-size: max-content;
    }

    .column{
        flex: 100%;
    }

    .item-card{
        height: auto;
    }

    .message{
        font-size: 14px;
    }
}

@media (max-width: 375px) {

    .column{
        flex: 100%;
    }

    .paper{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 15px 10px;
    }

    .item-card{
        height: auto;
    }

    .cartSection{
        z-index: 10;
        visibility: visible;
    }

    .number-input{
        width: 197px !important;  
    }

    .header{
        font-size: 20px;
    }

    .btn-purchase{
        width: 90%;
        /* font-size: 14px; */
    }

    .message{
        font-size: 14px;
    }

    .paper-box{
        width: 98%;
        align-items:unset;
    }

    .message-footer{
        bottom: 30px;
        width: 100vw;
    }

    .btn-confirm{
        width: 80%; 
        height: 6vh;
    }
}