#error{
    animation: fill1 0.5s ease forwards;
}

#error path:nth-child(1){
    stroke-dasharray: 1060px;
    stroke-dashoffset: 1060px;
    animation: line-anim 2s ease forwards;
}

@keyframes line-anim{
    to{
        stroke-dashoffset: 0;
    }
}

@keyframes fill1{
    /* from{
        fill:transparent;
    } */
    to{
        fill: '#73879C';
    }
}